.rpo-container {
  position: relative;
  width: 100%;
}

.rpo-header-wrapper {
  position: relative;
  width: 100%;
}

.rpo-background-image {
  width: 100%;
  height: auto;
  display: block;
}

.rpo-overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  box-sizing: border-box;
}

.rpo-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.rpo-text {
  font-family: 'Bebas Neue', sans-serif;
  color: #176f89;
  margin: 0.25rem 0;
  line-height: 1.2;
}

.rpo-text-primary {
  font-size: clamp(1.5rem, 5vw, 3.5rem);
}

.rpo-text-solution {
  background-color: white;
  color: #f79b24;
  padding: 0.25rem 0.75rem;
  font-size: clamp(1rem, 4vw, 1.8rem);
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .rpo-overlay-text {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
  }

  .rpo-text-container {
    align-items: flex-start;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .rpo-text {
    font-size: clamp(1.2rem, 4vw, 2.5rem);
  }

  .rpo-text-solution {
    font-size: clamp(0.9rem, 3vw, 1.5rem);
    padding: 0.2rem 0.5rem;
  }
}

@media (max-width: 576px) {
  .rpo-overlay-text {
    padding: 0.5rem;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .rpo-text {
    font-size: clamp(1rem, 3vw, 2rem);
  }

  .rpo-text-solution {
    font-size: clamp(0.8rem, 2.5vw, 1.2rem);
    padding: 0.15rem 0.4rem;
  }
  .rpo-text-container {
      align-items: flex-start;
      text-align: left;
    }
}