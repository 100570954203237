.jobsHedTxt{
    color:#176F89;
    font-size: 60px;
}
.infraThumsMainDiv{
   /*  width: 360px; */
}
.infraThumsImg{
    height: 211px;
    width: 100%;
}
.infraThumHedTxt{
    color: #1D1D1D;
    font-size: 24px;
    line-height: normal;
    text-transform: uppercase;
}
.infraThumDescTxt{
    color: #707070;
    font-size: 18px;
    line-height: normal;
}   

.infraThumRowBg{
    background-color: #f0f0f0;    
}