body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: "BebasNeue";
  src: local("BebasNeue"),
    url("./fonts/BebasNeue.otf") format("opentype");
}

@font-face {
  font-family: "BebasNeueRegular";
  src: local("BebasNeueRegular"),
    url("./fonts/BebasNeue-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ChamLimoBI";
  src: local("ChamLimoBI"),
    url("./fonts/Champagne & Limousines Bold Italic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
  font-family: "ChamLimoB";
  src: local("ChamLimoB"),
    url("./fonts/Champagne & Limousines Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "ChamLimoI";
  src: local("ChamLimoI"),
    url("./fonts/Champagne & Limousines Italic.ttf") format("truetype");
    font-style: italic;
}

@font-face {
  font-family: "ChamLimo";
  src: local("ChamLimo"),
    url("./fonts/Champagne & Limousines.ttf") format("truetype");
}
@font-face {
  font-family: "GiorgioSansBold";
  src: local("GiorgioSansBold"),
    url("./fonts/GiorgioSans-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
  font-family: "Japers";
  src: local("Japers"),
    url("./fonts/Japers.otf") format("opentype");
}
@font-face {
  font-family: "OpenSansBold";
  src: local("OpenSansBold"),
    url("./fonts/OpenSans-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
  font-family: "OpenSansLight";
  src: local("OpenSansLight"),
    url("./fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: "OpenSansMedium";
  src: local("OpenSansMedium"),
    url("./fonts/OpenSans-Medium.ttf") format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url("./fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ThasadithB";
  src: local("ThasadithB"),
    url("./fonts/Thasadith-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
  font-family: "ThasadithBI";
  src: local("ThasadithBI"),
    url("./fonts/Thasadith-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "ThasadithI";
  src: local("ThasadithI"),
    url("./fonts/Thasadith-Italic.ttf") format("truetype");
  font-style: italic;
}
@font-face {
  font-family: "Thasadith";
  src: local("Thasadith"),
    url("./fonts/Thasadith-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Corki";
  src: local("Corki"),
    url("./fonts/Corki-Rounded.otf") format("opentype");
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
