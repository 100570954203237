/* .esdmHeadTxt{
    font-size: 44px;
    color: #176F89;
}

.esdmImgBg {
    min-height: 211px;
    width: 100%;
    background-color: #E8E8E8;
}
.esdmImgBg_dummy{
    min-height: 211px;
    width: 100%;
    background-color: #ffffff;
}


.esdmThumMainDiv {
    width: 420PX;
}
.esdmThumHedTxt{
    color: #000000;
    font-size: 24px;    
}
.esdmThumdescTxt{
    color:#707070;
    font-size: 18px;
} */

.esdmHeadTxt {
  font-size: 2.75rem;
  color: #176f89;
  text-align: center;
  margin-bottom: 1rem;
}

.esdmImgBg {
  min-height: 211px;
  width: 100%;
  background-color: #e8e8e8;
}

.esdmImgBg_dummy {
  min-height: 211px;
  width: 100%;
  background-color: #ffffff;
}

.esdmThumMainDiv {
  width: 100%;
  max-width: 420px;
  margin: 1rem auto;
  box-sizing: border-box;
}

.esdmThumHedTxt {
  color: #000000;
  font-size: 1.5rem;
  text-align: center;
}

.srcimg {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover; 
}

.esdmThumdescTxt {
    color: #707070;
    font-size: 1rem;
    text-align: justify;
    margin: 0 auto;
}

@media (max-width: 992px) {
  .esdmHeadTxt {
    font-size: 2.25rem;
  }

  .esdmThumHedTxt {
    font-size: 1.25rem;
  }

  .esdmThumdescTxt {
    font-size: 0.95rem;
  }

  .esdmThumMainDiv {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .row.g-0 {
    flex-direction: column;
  }

  .esdmHeadTxt {
    font-size: 2rem;
  }

  .esdmThumMainDiv {
    max-width: 90%;
    padding: 1rem;
  }

  .esdmThumHedTxt {
    font-size: 1.15rem;
  }

  .esdmThumdescTxt {
    font-size: 0.95rem;
    text-align: center; 
}
.col-8 {
    width: 100%; 
    padding: 0 1rem; 
}
}

@media (max-width: 576px) {
  .esdmHeadTxt {
    font-size: 1.75rem;
  }

  .esdmThumHedTxt {
    font-size: 1rem;
  }

  .esdmThumdescTxt {
    font-size: 0.85rem;
  }
}
