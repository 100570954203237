/* .homeImg{
    width: 100%;
}

.imageTxtBgMain{
    width: 100%;
    bottom: 50px;
    height: 172px;
}

.imageTxtBg{
    background: #000000;
    opacity: 0.6;
    min-width: 100%;
    min-height: 100%;
}

.imgHeadTxt1{
    color: #FFFFFF;
    font-size: 85px;
    top: 5px;
}
.imgHeadTxt2{
    color: #F79B24;
    font-size: 85px;
    top: 5px;
}
.imgDescTxt{
    color:  #FFFFFF;
    font-size: 18px;
    top: -15px;    
} */

.homeImg {
    width: 100%;
    height: auto; /* Ensures the image maintains its aspect ratio */
}

.imageTxtBgMain {
    width: 100%;
    bottom: 50px;
    height: 172px;
    display: flex;
    align-items: center; /* Centers content vertically */
    justify-content: center; /* Centers content horizontally */
}

.imageTxtBg {
    background: #000000;
    opacity: 0.6;
    min-width: 100%;
    min-height: 100%;
}

.imgHeadTxt1 {
    color: #FFFFFF;
    font-size: 85px;
    top: 5px;
    line-height: 1; /* Prevents text from overlapping */
}

.imgHeadTxt2 {
    color: #F79B24;
    font-size: 85px;
    top: 5px;
    line-height: 1;
}

.imgDescTxt {
    color: #FFFFFF;
    font-size: 18px;
    top: 15px;
    text-align: center; /* Centers the description text */
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .imgHeadTxt1,
    .imgHeadTxt2 {
        font-size: 65px; /* Reduces heading font size for medium screens */
    }
    .imgDescTxt {
        font-size: 16px; /* Adjusts description text size */
    }
    .imageTxtBgMain {
        height: 150px; /* Adjusts the height of the background container */
    }
}

@media (max-width: 768px) {
    .imgHeadTxt1,
    .imgHeadTxt2 {
        font-size: 50px; /* Further reduces heading font size for small screens */
    }
    .imgDescTxt {
        font-size: 14px; /* Adjusts description text size for better readability */
        padding: 0 10px; /* Adds padding for smaller screens */
    }
    .imageTxtBgMain {
        height: 130px; /* Adjusts container height */
        bottom: 30px; /* Adjusts position */
    }
}

@media (max-width: 576px) {
    .imgHeadTxt1,
    .imgHeadTxt2 {
        font-size: 40px; /* Reduces heading size further for extra small screens */
    }
    .imgDescTxt {
        font-size: 12px; /* Smaller font for description text */
        padding: 0 5px; /* Adds more padding for very small screens */
    }
    .imageTxtBgMain {
        height: 110px; /* Further reduces height */
        bottom: 20px; /* Adjusts position */
    }
}
