/* .rpsPageInofMainBgDiv{
    background-color: #f0f0f0;
}
.rpsPageTxtConttWidth{
   width: 586px;
}

.rpsInfoImg{
    height: 100%;
    width: 443px;
}
.rpsPagInfoHedTxt{
    color: #176F89;
    font-size: 56px;
    line-height: normal;
}
.rpsPagInfoDescTxt{
    color: #707070;
    font-size: 22px;
    line-height: normal;
} */

.rpsPageInofMainBgDiv {
    background-color: #f0f0f0;
}

.rpsPageTxtConttWidth {
    width: 100%; /* Make it fluid for responsiveness */
    max-width: 586px; /* Maintain the original width on larger screens */
}

.rpsInfoImg {
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Scale down on smaller screens */
    width: 443px; /* Default width */
}

.rpsPagInfoHedTxt {
    color: #176F89;
    font-size: 56px;
    line-height: normal;
}

.rpsPagInfoDescTxt {
    color: #707070;
    font-size: 22px;
    line-height: normal;
}

/* Responsive Design */


@media (max-width: 992px) {
    .rpsPageTxtConttWidth {
        width: 90%; /* Adjust content width */
    }
    .rpsPagInfoHedTxt {
        font-size: 40px;
    }
    .rpsPagInfoDescTxt {
        font-size: 18px;
    }
}

@media (max-width: 768px) {
    .d-flex {
        flex-direction: column; /* Stack items vertically */
    }
    .rpsInfoImg {
        width: 100%; /* Full width for smaller screens */
    }
    .rpsPagInfoHedTxt {
        font-size: 36px;
    }
    .rpsPagInfoDescTxt {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .rpsPagInfoHedTxt {
        font-size: 32px; /* Smaller heading for mobile */
    }
    .rpsPagInfoDescTxt {
        font-size: 14px; /* Smaller description text */
    }
    .px-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .p-5 {
        padding: 1.5rem !important; /* Reduce padding */
    }
}
