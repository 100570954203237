 /* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}  */
.imageBg {
  background-color: #cccccc;
  position: relative; /* Ensures children are placed correctly */
}


.imgHedBgDiv {
  background: #4a4a4a;
  opacity: 0.85;
  width: 100%;
  line-height: normal;
  min-height: 105px;
  position: relative; /* Changed from absolute */
  margin-top: -50px; /* Adjust based on image */
}

.imgHedBgDiv2 {
  background: #0f0f0f;
  opacity: 0.85;
  height: auto;
  width: 100%;
  position: relative; /* Changed from absolute */
  margin-top: 20px; /* Avoid overlap */
  padding: 10px;
}

.imgHedBgDiv2TxtSpan {
  text-align: center;
  color: #ffffff;
  font-size: 1rem;
  display: inline-block;
  width: 90%;
}

.imgHeadBgTxtSpan1 {
  color: #ffffff;
  font-size: 4.5rem;
  margin-right: 15px;
}
.imgHeadBgTxtSpan2 {
  color: #ffae46;
  font-size: 4.5rem;
  margin-right: 20px;
}
.imgHeadBgTxtSpan3 {
  color: #ffffff;
  font-size: 4.5rem;
  margin-right: 15px;
}
.imgHeadBgTxtSpan4 {
  color: #41bfff;
  font-size: 4.5rem;
} 
@media (max-width: 1200px) {
  .imgHeadBgTxtSpan1, .imgHeadBgTxtSpan2, .imgHeadBgTxtSpan3, .imgHeadBgTxtSpan4 {
    font-size: 3.5rem;
  }
  .imgHedBgDiv2TxtSpan {
    font-size: 0.9rem;
    width: 90%;
  }
}

@media (max-width: 992px) {
  .imgHeadBgTxtSpan1, .imgHeadBgTxtSpan2, .imgHeadBgTxtSpan3, .imgHeadBgTxtSpan4 {
    font-size: 3rem;
  }
  .imgHedBgDiv2TxtSpan {
    font-size: 0.85rem;
    width: 85%;
  }
}

/* @media (max-width: 768px) {
  .imgHedBgDiv {
    flex-direction: column;
    padding: 10px;
  }
  .imgHeadBgTxtSpan1, .imgHeadBgTxtSpan2, .imgHeadBgTxtSpan3, .imgHeadBgTxtSpan4 {
    font-size: 2.5rem;
    margin: 5px 0;
  }
  .imgHedBgDiv2 {
    height: auto;
    padding: 15px;
  }
  .imgHedBgDiv2TxtSpan {
    font-size: 0.8rem;
    width: 80%;
  }
}

@media (max-width: 576px) {
  .imgHeadBgTxtSpan1, .imgHeadBgTxtSpan2, .imgHeadBgTxtSpan3, .imgHeadBgTxtSpan4 {
    font-size: 2rem;
  }
  .imgHedBgDiv2TxtSpan {
    font-size: 0.75rem;
    width: 75%;
  }
} */
@media (max-width: 768px) {
  .imgHedBgDiv {
    flex-direction: column;
    padding: 10px;
    position: relative; /* Prevent overlap */
    top: auto;
    min-height: auto;
  }
  .imgHeadBgTxtSpan1, .imgHeadBgTxtSpan2, .imgHeadBgTxtSpan3, .imgHeadBgTxtSpan4 {
    font-size: 2.5rem;
    margin: 5px 0;
    text-align: center;
  }
  .imgHedBgDiv2 {
    height: auto;
    padding: 15px;
    position: relative; /* Prevents absolute overlap */
    top: auto;
  }
  .imgHedBgDiv2TxtSpan {
    font-size: 0.8rem;
    width: 90%;
  }
}

@media (max-width: 576px) {
  .imgHeadBgTxtSpan1, .imgHeadBgTxtSpan2, .imgHeadBgTxtSpan3, .imgHeadBgTxtSpan4 {
    font-size: 2rem;
  }
  .imgHedBgDiv2TxtSpan {
    font-size: 0.75rem;
    width: 90%;
  }
}

