/* .supSerImgBg{
    min-height: 211px;
    width: 100%;
    background-color: #E8E8E8;
}
.supSerMainDiv{
    width: 582px;
} 
.supSerHedTxt{
    color: #1D1D1D;
    font-size: 28px;
}

.supSerDesTxt{
    color: #707070;
    font-size: 18px;
}
.suptSecBg{
    background-color: #f0f0f0;
} */

/* .suptSecBg {
    background-color: #f0f0f0;
    padding: 2rem 1rem;
}

.supSerImgBg {
    min-height: 211px;
    width: 100%;
    background-color: #E8E8E8;
}

.supSerMainDiv {
    width: 100%;
    max-width: 582px;
    margin: 0 auto;
}
.supSerMainDiv img {
    width: 100%;
    height: auto; 
    border-radius: 8px; 
    object-fit: cover; 
}

.supSerHedTxt {
    color: #1D1D1D;
    font-size: 1.75rem;
    text-align: center;
}

.supSerDesTxt {
    color: #707070;
    font-size: 1rem;
    text-align: justify;
}




@media (max-width: 992px) {
    .supSerMainDiv {
        padding: 1rem;
        max-width: 90%;
    }
    .supSerHedTxt {
        font-size: 1.5rem;
    }
    .supSerDesTxt {
        font-size: 0.95rem;
    }
}

@media (max-width: 768px) {
    
    
    .supSerMainDiv {
        max-width: 100%;
        padding: 1rem;
    }
    .supSerMainDiv img {
        border-radius: 4px; 
        width: 100%;
    }
    .supSerHedTxt {
        font-size: 1.25rem;
    }
    .supSerDesTxt {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .supSerHedTxt {
        font-size: 1rem;
    }
    .supSerDesTxt {
        font-size: 0.85rem;
    }
    .supSerMainDiv img {
        border-radius: 2px; 
    }
    
} */

.support-services-container {
    background-color: #f0f0f0;
    padding: 2rem 1rem;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .service-card {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
  
  .service-content {
    width: 90%;
    max-width: 582px;
  }
  
  .service-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .service-title {
    font-family: 'Thasadith', sans-serif;
    color: #1D1D1D;
    font-size: 1.75rem;
    text-align: center;
    margin-top: 1rem;
  }
  
  .service-description {
    font-family: 'Open Sans', sans-serif;
    color: #707070;
    font-size: 1rem;
    text-align: justify;
    margin-top: 1rem;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 992px) {
    .services-grid {
      grid-template-columns: 1fr;
    }
  
    .service-content {
      width: 90%;
      max-width: 100%;
    }
  
    .service-title {
      font-size: 1.5rem;
    }
  
    .service-description {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 768px) {
    .service-title {
      font-size: 1.25rem;
    }
  
    .service-description {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 576px) {
    .service-title {
      font-size: 1rem;
    }
  
    .service-description {
      font-size: 0.85rem;
    }
  
    .service-image {
      border-radius: 4px;
    }
  }