.font-face-BebasNeue {
  font-family: "BebasNeue";
}

.font-face-BebasNeueRegular {
  font-family: "BebasNeueRegular";
}

.font-face-ChamLimoBI {
  font-family: "ChamLimoBI";
}

.font-face-ChamLimoB {
  font-family: "ChamLimoB";
}

.font-face-ChamLimoI {
  font-family: "ChamLimoI";
}

.font-face-ChamLimo {
  font-family: "ChamLimo";
}
.font-face-GiorgioSansBold {
  font-family: "GiorgioSansBold";
}

.font-face-Japers {
  font-family: "Japers";
}

.font-face-OpenSansBold {
  font-family: "OpenSansBold";
}

.font-face-OpenSansLight {
  font-family: "OpenSansLight";
}

.font-face-OpenSansMedium {
  font-family: "OpenSansMedium";
}

.font-face-OpenSans {
  font-family: "OpenSans";
}

.font-face-ThasadithB {
  font-family: "ThasadithB";
}
.font-face-ThasadithBI {
  font-family: "ThasadithBI";
}
.font-face-ThasadithI {
  font-family: "ThasadithI";
}
.font-face-Thasadith {
  font-family: "Thasadith";
}
.font-face-Corki {
  font-family: "Corki";
}



