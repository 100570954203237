.connectMainDiv{
    background-color: #202020;
    width: 100%;
    min-height: 200px;
}
.connHeadTxt{
    color: #60C3FF;
    font-size: 60px;
}
.contTextColor{
    color: #FFFFFF;
    font-size: 14px;
    width: 400px;
}

.connMsgDiv{
    border:1px solid white;
    min-height: 87px;
    min-width: 100px;
}
.connBtnDiv{
    color: rgb(255, 255, 255);
    background-color: #268ccc; padding: 5px;
        display: inline-block;
        width: 132px;
        height: 36px;
        border-radius: 5px;
        text-align: center;
}
.conntIcon{
    height: 26px;
    width: 26px;
    margin-right: 5px;
}
.conntMarg{
    margin-right: 30px;
} 
/* .connectMainDiv {
  background-color: #202020;
  width: 100%;
  min-height: 200px;
  padding: 20px;
}

.connHeadTxt {
  color: #60c3ff;
  font-size: 60px;
}

.contTextColor {
  color: #ffffff;
  font-size: 14px;
  width: 400px;
}

.connMsgDiv {
  border: 1px solid white;
  min-height: 87px;
  min-width: 100px;
}

.connBtnDiv {
  color: rgb(255, 255, 255);
  background-color: #268ccc;
  padding: 5px;
  display: inline-block;
  width: 132px;
  height: 36px;
  border-radius: 5px;
  text-align: center;
}

.conntIcon {
  height: 26px;
  width: 26px;
  margin-right: 5px;
}

.conntMarg {
  margin-right: 30px;
} */

 @media (max-width: 768px) {
  .connHeadTxt {
    font-size: 40px;
    text-align: center;
  }

  .contTextColor {
    font-size: 12px;
    width: 100%;
    margin-bottom: 10px;
  }

  .connMsgDiv {
    width: 100%;
    min-height: 70px;
  }

  .connBtnDiv {
    width: 100%;
    height: 40px;
    padding: 10px 0;
  }

  .connectMainDiv {
    flex-wrap: wrap;
    justify-content: center;
  }

  .conntMarg {
    margin-right: 0;
    text-align: center;
  }

  .conntIcon {
    height: 20px;
    width: 20px;
  }
} 


@media (max-width: 480px) {
  .connHeadTxt {
    font-size: 28px;
    text-align: center;
  }

  .contTextColor {
    font-size: 12px;
    text-align: center; 
  }

  .connMsgDiv {
    width: 100%; 
    min-height: 60px;
  }

  .connBtnDiv {
    font-size: 14px; 
    width: 100%; 
    height: 40px;
  }

  .conntIcon {
    height: 18px; 
    width: 18px;
    margin-right: 5px;
  }

  .connectMainDiv {
    padding: 10px; 
  }
} 
