/* .pageDescTxt{
    font-size: 1.2rem;
    color: #FFFFFF;
    
}

.pageDescBg{
    background-color: #444444;
} */
.pageDescTxt {
    font-size: 1.2rem;
    color: #FFFFFF;
    line-height: 1.5;
    padding: 0 1rem; /* Add horizontal padding for better spacing on smaller screens */
}

.pageDescBg {
    background-color: #444444;
    padding: 2rem 0; /* Add vertical padding for spacing */
}

/* Responsive Design */
@media (max-width: 992px) {
    .pageDescTxt {
        font-size: 1rem; /* Reduce text size for medium screens */
        padding: 0 1.5rem; /* Adjust padding */
    }

    .col-7 {
        max-width: 90%; /* Reduce text container width */
    }
}

@media (max-width: 768px) {
    .pageDescTxt {
        font-size: 0.9rem; /* Further reduce text size for smaller screens */
        padding: 0 1rem; /* Ensure proper spacing */
    }

    .col-7 {
        max-width: 95%; /* Allow more flexibility for smaller screens */
    }
}

@media (max-width: 576px) {
    .pageDescTxt {
        font-size: 0.8rem; /* Optimize readability on mobile */
        padding: 0 0.5rem; /* Minimal padding on very small screens */
    }

    .col-7 {
        max-width: 100%; /* Use the full width on mobile */
    }
}
