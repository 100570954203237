/* .ouExpHedTxt{
    text-align: center;
    font-size: 56px;
    color: #000000;
}
.ouExpDescTxt{
    text-align: center;
    font-size: 18px;
    color: #5D5D5D;
}
.descTxt{
    font-size: 16px;
    color: #5D5D5D;
    width: 940px;
}  */
 
.ouExpHedTxt {
    text-align: center;
    font-size: 3.5rem;  /* Adjust font size for responsiveness */
    color: #000000;
    margin-bottom: 20px;
}

.ouExpDescTxt {
    text-align: center;
    font-size: 1rem;
    color: #5D5D5D;
}

.descTxt {
    font-size: 1rem;
    color: #5D5D5D;
    width: 80%; /* Make the width responsive */
    max-width: 940px;
    margin: 0 auto;
    text-align: center;
}

img {
    max-width: 100%;  /* Make image responsive */
    height: auto;
}

@media (max-width: 1200px) {
    .ouExpHedTxt {
        font-size: 3rem;  /* Adjust font size for large screens */
    }

    .descTxt {
        font-size: 1rem;
        width: 90%;
    }
}

@media (max-width: 992px) {
    .ouExpHedTxt {
        font-size: 2.5rem;  /* Adjust font size for medium screens */
    }

    .descTxt {
        font-size: 0.95rem;
        width: 90%;
    }
}

@media (max-width: 768px) {
    .ouExpHedTxt {
        font-size: 2rem;  /* Adjust font size for tablets */
    }

    .descTxt {
        font-size: 0.9rem;
        width: 90%;
        text-align: center;
    }
}

@media (max-width: 576px) {
    .ouExpHedTxt {
        font-size: 1.5rem;  /* Adjust font size for small screens */
    }

    .descTxt {
        font-size: 0.85rem;
        width: 95%;
        text-align: center;
    }
}

