.infraImg {
    width: 100%;
}

.infraTxtTop{
    top: 0px;
    width: 100%;
}

.infraImgTxt1 {
    color: #FFFFFF;
    font-size: 73px;
    line-height: normal;
    background-color: #FFC500;
    width: 109px;
}

.infraImgTxt2 {
    color: #FFC500;
    font-size: 81px;
    line-height: normal;
    background-color: #000000;
    width: 450px;
}

.infraImgTxt3 {
    color: #000000;
    font-size: 16px;
    line-height: normal;
    background-color: #FFFFFF;
    width: 470px;
}