.quantum-container {
    position: relative;
    width: 100%;
  }
  
  .quantum-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
  }
  
  .overlay-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: rgba(67, 67, 67, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
  }
  
  .header-content {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .why-choose {
    color: #FFFFFF;
    text-align: right;
  }
  
  .why-choose p {
    font-family: 'BebasNeueRegular', sans-serif;
    font-size: clamp(2.5rem, 4vw, 3.5rem);
    line-height: 1;
    margin: 0;
  }
  
  .question-mark {
    color: #F79B24;
    font-family: 'BebasNeueRegular', sans-serif;
    font-size: clamp(4rem, 6vw, 7rem);
    line-height: 1;
    margin-top: -1rem;
  }
  
  .title-content {
    margin-top: 1rem;
  }
  
  .quantum-title {
    color: #7BCAD2;
    font-family: 'BebasNeueRegular', sans-serif;
    font-size: clamp(2rem, 3.6vw, 4rem);
    line-height: 1;
    margin: 0;
  }
  
  .technologies {
    color: #7BCAD2;
    font-family: 'BebasNeueRegular', sans-serif;
    font-size: clamp(1.2rem, 2vw, 2.2rem);
    margin-top: 0.5rem;
  }
  
  .description {
    color: #FFFFFF;
    font-family: 'OpenSans', sans-serif;
    font-size: clamp(14px, 1.5vw, 16px);
    line-height: 1.6;
    margin-top: 2rem;
    max-width: 600px;
  }
  
  @media (max-width: 768px) {
    .overlay-content {
      width: 100%;
      position: relative;
      padding: 1.5rem;
    }
  
    .header-content {
      justify-content: center;
    }
  
    .title-content {
      text-align: center;
    }
  
    .description {
      text-align: center;
      margin: 2rem auto 0;
    }
  }
  
  @media (max-width: 480px) {
    .overlay-content {
      padding: 1rem;
    }
  
    .why-choose p {
      font-size: 2rem;
    }
  
    .question-mark {
      font-size: 3rem;
    }
  
    .quantum-title {
      font-size: 2.5rem;
    }
  
    .technologies {
      font-size: 1.5rem;
    }
  
    .description {
      font-size: 14px;
    }
  }