
.jobPageBg {
    background: #F0F0F0;
    padding: 16px; 
}


.jobPageHedTxt {
    color: #176F89;
    font-size: 60px;
    text-align: center;
}


.jobPageDescTxt {
    color: #707070;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
}


.jobDetailBg {
    background: #FFFFFF;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}


.jobHeadTxt {
    color: #707070;
    font-size: 26px;
    margin-bottom: 10px;
}


.jobDescTxt {
    color: #707070;
    font-size: 16px;
}


.infoButton {
    background-color: #176F89;
    color: white;
    padding: 8px 20px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.infoButton:hover {
    background-color: #0f5e6e;
}


.jobPreBtnSpan,
.jobNextBtnSpan {
    background: white;
    color: #707070;
    font-size: 20px;
    padding: 8px 18px;
    border-radius: 25px;
    cursor: pointer;
    border: 1px solid #707070;
}


@media (max-width: 768px) {
    .jobPageHedTxt {
        font-size: 40px; 
    }

    .jobPageDescTxt {
        font-size: 14px; 
    }

    .jobHeadTxt {
        font-size: 22px;
    }

    .jobDescTxt {
        font-size: 14px; 
    }

    .infoButton {
        padding: 6px 16px; 
        font-size: 14px; 
    }

    .jobPreBtnSpan, .jobNextBtnSpan {
        padding: 6px 16px; 
        font-size: 16px; 
    }

    
    .jobDetailBg {
        padding: 15px; 
    }
}

@media (max-width: 480px) {
    .jobPageHedTxt {
        font-size: 30px; 
    }

    .jobPageDescTxt {
        font-size: 12px; 
    }

    .jobHeadTxt {
        font-size: 20px; 
    }

    .jobDescTxt {
        font-size: 12px; 
    }

    .infoButton {
        padding: 5px 12px; 
        font-size: 12px; 
    }

    .jobPreBtnSpan, .jobNextBtnSpan {
        padding: 5px 12px; 
        font-size: 14px; 
    }
}
