/* .design-services-container {
  padding: 20px;
}

.desSerYellowDiv {
  width: 226px;
  height: 121px;
  background-color: #ffb704;
  margin: auto;
}

.desSerYellowTxt {
  color: #ffffff;
  font-size: 90px;
}

.desSerBlackDiv {
  height: 94px;
  width: 226px;
  background-color: #313131;
  margin: auto;
}

.desSerBlackTxt {
  color: #ffffff;
  font-size: 68px;
}

.designSerImg {
  max-width: 100%;
  height: auto;
  margin: auto;
}

.designSerTxt1,
.designSerTxt2 {
  color: #5d5d5d;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .design-services-left,
  .design-services-right {
    text-align: center;
  }

  .designSerTxt1,
  .designSerTxt2 {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .desSerYellowTxt {
    font-size: 50px;
  }

  .desSerBlackTxt {
    font-size: 40px;
  }

  .designSerTxt1,
  .designSerTxt2 {
    font-size: 14px;
  }
} */
.design-services-container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.design-services-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.desSerYellowDiv {
  width: 226px;
  height: 121px;
  background-color: #ffb704;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desSerYellowTxt {
  color: #ffffff;
  font-size: 90px;
}

.desSerBlackDiv {
  width: 226px;
  height: 94px;
  background-color: #313131;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desSerBlackTxt {
  color: #ffffff;
  font-size: 68px;
}

.designSerImg {
  max-width: 100%;
  height: auto;
}

.designSerTxt1,
.designSerTxt2 {
  color: #5d5d5d;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .design-services-container {
    flex-direction: column;
  }
  .designSerTxt1,
  .designSerTxt2 {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .desSerYellowTxt {
    font-size: 50px;
  }
  .desSerBlackTxt {
    font-size: 40px;
  }
  .designSerTxt1,
  .designSerTxt2 {
    font-size: 14px;
  }
}
