.logoImg {
  height: 53px;
  width: 85px;
}

.minHeight {
  height: 80px;
}
.logHeadingTxt1 {
  color: #4e4e4e;
  font-size: 2.5rem;
  line-height: normal;
  letter-spacing: -1px;
  padding-left: 8px;
}
.logHeadingTxt2 {
  color: #6ba7d0;
  font-size: 2.5rem;
  margin-left: 0.2rem;
  line-height: normal;
  letter-spacing: -1px;
}
.logHeadingTxt3 {
  color: #707070;
  font-size: 1rem;
  display: inline;
  position: absolute;
  top: 2.8rem;
  line-height: normal;
  padding-left: 8px;
}
.companyNameTop {
  top: -8px;
}
/* .hedingMinHeight{
    min-height: 70px; 
} */

.link-styles {
  text-decoration: unset;
  color: unset;

  color: blue;
  font-size: 14px;
  padding-left: 1px;
  padding-right: 4px;
  cursor: pointer !important;
}

.menuTxt {
  color: #0e0e0e !important;
  font-size: 15px;
  cursor: pointer !important;
  text-decoration: none !important;
}
.menuSeperator {
  color: #d6d6d6;
  font-size: 12px;
}
.inpTxt {
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 0.45;
  padding: 3px;
  width: 130px;
  margin-left: 12px;
  padding-left: 10px;
  color: #2b2f32;
  font-size: 12px;
}

.logoImg {
  height: auto;
  max-height: 53px;
  width: auto;
  max-width: 85px;
}

@media (max-width: 1300px) {
  .minHeight {
    height: auto;
    padding: 10px 20px;
  }

  .logoImg {
    max-height: 50px;
    max-width: 80px;
  }

  .logHeadingTxt1,
  .logHeadingTxt2 {
    font-size: 1.8rem;
  }

  .logHeadingTxt3 {
    font-size: 0.9rem;
    position: static;
    margin-top: 5px;
  }

  .inpTxt {
    width: 150px; /* Increased width for better responsiveness */
    font-size: 12px;
    margin-left: 10px;
    margin-top: 5px;
    max-width: 100%; /* Ensures the search box is responsive */
  }

  /* Adjusting flexbox layout */
  .col-4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap; /* Allow wrapping when there's not enough space */
  }

  .col-5 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
  }
}

@media (max-width: 992px) {
  .minHeight {
    height: auto;
    padding: 10px 20px;
  }

  .logHeadingTxt1,
  .logHeadingTxt2 {
    font-size: 2rem;
  }

  .logHeadingTxt3 {
    font-size: 0.9rem;
    position: static;
    top: 2.4rem;
  }

  .inpTxt {
    width: 100px;
    font-size: 11px;
    margin-left: 10px;
    margin-top: 5px;
  }
  .col-4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
  }
  .inpTxt {
    width: 120px;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .row.g-0 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .col-4,
  .col-5 {
    width: 100%;
    padding: 10px 0;
  }

  .logoImg {
    max-height: 45px;
    max-width: 70px;
  }

  .logHeadingTxt1,
  .logHeadingTxt2 {
    font-size: 1.8rem;
  }

  .logHeadingTxt3 {
    font-size: 0.85rem;
    position: static;
    top: 2.2rem;
  }

  .inpTxt {
    width: 90px;
    font-size: 10px;
    margin-top: 10px;
  }

  .menuTxt {
    font-size: 14px;
  }

  .menuSeperator {
    display: none;
  }
}

@media (max-width: 576px) {
  .row.g-0 {
    flex-direction: column;
    align-items: center;
  }

  .col-4,
  .col-5 {
    width: 100%;
    text-align: center;
  }

  .inpTxt {
    width: 80px;
    margin-top: 5px;
  }

  .menuTxt {
    font-size: 13px;
    display: block;
    margin: 5px 0;
  }

  .link-styles {
    display: block;
    font-size: 13px;
  }
}
