/* .copMainDiv{
    background-color: #000000;
    width: 100%;
}
.copywriteTxt{
    color: white;
} */

.copMainDiv {
    background-color: #000000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-sizing: border-box;
}

.copywriteTxt {
    color: white;
    font-size: 1rem;
    text-align: center;
    word-wrap: break-word;
}

@media (max-width: 768px) {
    .copywriteTxt {
        font-size: 0.9rem;
        padding: 0.5rem;
    }
}

@media (max-width: 480px) {
    .copywriteTxt {
        font-size: 0.8rem;
    }
}