


 .empowerImg {
  width: 100%;
  height: auto; 
  max-width: 100%; 
}
.empowerHedMainDiv {
  border: 1px solid #fafafa;
  font-size: 52px;
}
.empoHedTxt1Span {
  color: #fafafa;
  font-size: 37px;
  margin: 15px;
  margin-top: 0px;
}
.empoHedTxt2Span {
  color: #234e85;
  background-color: #fafafa;
  margin: 0px;
}

.empoBotTxtMainDiv {
  background-color: #000000;
  width: 100%;
  bottom: 30px;
  height: 110px;
  margin-bottom: 10px;
}

.empBotTxtSpan {
  font-size: 17px;
  color: #ffffff;
  position: relative;
  display: block;
  width: 790px;
  padding: 10px;
  text-align: center;
  top: 5px;
}

.empHedTopPosi {
  top: 40px;
}

@media (max-width: 768px) {
  .empowerHedMainDiv {
    font-size: 42px;
    padding: 10px;
  }

  .empoHedTxt1Span {
    font-size: 28px;
    margin: 10px;
  }

  .empoHedTxt2Span {
    font-size: 30px;
    padding: 5px;
  }

  .empoBotTxtMainDiv {
    height: auto;
    padding: 15px 0;
  }

  .empBotTxtSpan {
    font-size: 15px;
    width: 90%;
  }

  .empHedTopPosi {
    top: 20px;
  }
}


@media (max-width: 480px) {
  .empowerHedMainDiv {
    font-size: 32px;
    text-align: center;
  }

  .empoHedTxt1Span {
    font-size: 24px;
    margin: 5px;
  }

  .empoHedTxt2Span {
    font-size: 25px;
    padding: 3px;
  }

  .empoBotTxtMainDiv {
    height: auto;
    bottom: 15px;
    margin-bottom: 5px;
  }

  .empBotTxtSpan {
    font-size: 14px;
    width: 90%;
    padding: 5px;
  }

  .empHedTopPosi {
    top: 10px;
  }
}
