.contactUsPageBg {
  background: #f0f0f0;
  min-height: 800px;
  padding: 20px;
}

.contactHedTxt {
  color: #176f89;
  font-size: 60px;
  margin-bottom: 20px;
}

.contactDecTxt {
  font-size: 16px;
  color: #707070;
  margin-bottom: 20px;
}

.contFldBg {
  background-color: #ffffff;
  height: 48px;
  width: 95%;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
}

.contFldInput {
  border: none;
  height: 100%;
  width: 100%;
  padding-left: 10px;
  color: #828282;
  font-size: 16px;
}

.contFldInput:focus {
  outline: none;
  box-shadow: none;
}

.contCommnetFldBg {
  width: 98%;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px;
  resize: none;
}

.contUsBtnSpan {
  background-color: #97b768;
  color: #ffffff;
  font-size: 24px;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contUsBtnSpan:hover {
  background-color: #82a054;
}
.conntIcon{
  height: 26px;
  width: 26px;
  margin-right: 5px;
}
.contactDetailsCard {
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    text-align: left;
  }
  
  .contactDetail {
    font-size: 16px;
    color: #707070;
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .contactDetail i {
    color: #176f89;
    margin-right: 10px;
  }
  
/* Responsiveness */
@media (max-width: 768px) {
  .contactHedTxt {
    font-size: 40px;
    text-align: center;
  }

  .contactDecTxt {
    font-size: 14px;
    text-align: center;
  }

  .contFldBg {
    width: 100%;
  }

  .contCommnetFldBg {
    width: 100%;
  }
  .contactDetailsCard {
    width: 95%;
    text-align: center;
  }

  .contactDetail {
    font-size: 14px;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .contactHedTxt {
    font-size: 28px;
    text-align: center;
    line-height: 1.2;
  }

  .contactDecTxt {
    font-size: 12px;
    text-align: center;
  }

  .contFldBg {
    width: 100%;
    height: 40px;
  }

  .contFldInput {
    font-size: 14px;
  }

  .contCommnetFldBg {
    width: 100%;
    padding: 8px;
  }

  .contUsBtnSpan {
    font-size: 18px;
    padding: 8px 16px;
    border-radius: 5px;
  }

  .contactUsPageBg {
    padding: 10px;
  }
  .contactDetailsCard {
    padding: 15px;
  }

  .contactDetail {
    font-size: 12px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contactDetail i {
    margin-bottom: 5px;
  }

}
